<template>
  <div>
    <div class="d-flex justify-content-between mb-1">

      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

    </div>

    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <opportunity-filter
        :filterparams="form"
        @searchData="GetSearchFilter"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(service_name)="data">
          <span class="">
            <b-badge variant="success">
              {{ data.item.service_name }}
            </b-badge>
          </span>
        </template>
        <template #cell(lead_assign_name)="data">
          <span class="">{{ data.item.lead_assign_name | arrayToStringVal }}</span>
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">
        <b-col
          cols="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + items.total }}
          </div>
          <b-form-group class="align-self-center mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getOpportunity"
            />
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>
    <vue-context
      ref="leadsContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link

          v-if="data.text == 'View More Details' && checkPermission(permissions.LEAD_MORE_DETAILS)"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link

          v-else-if="data.text != 'View More Details'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >

          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-if="valuesContext.move_to_epr == true"
          class="d-flex align-items-center"
          @click="sendToEPR()"
        >
          <feather-icon
            icon="SendIcon"
            size="16"
          />
          <span class="ml-75">{{ t('Send To EPR') }}</span>
        </b-link>
        <b-link
          class="d-flex align-items-center"
          @click="OpenRegenerateOpportunityModal(valuesContext)"
        >
          <feather-icon
            icon="CopyIcon"
            size="16"
          />
          <span class="ml-75">{{ t('Clone Opportunity') }}</span>
        </b-link>
      </li>
    </vue-context>
    <clone-opportunity-modal
      :clone-modal-data="cloneModalData"
    />
  </div>
</template>
<script>
import 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/utils/axiosCompany'
import store from '@/store'
import constants from '@/constants'
import OpportunityFilter from './OpportunityFilter.vue'
import {
  tableFilter, showLoader, hideLoader,
} from '@/utils/common'
import permissions from '@/permissions'
import checkPermissions from '@/utils/checkPermissons'
import CloneOpportunityModal from './CloneOpportunityModal.vue'

export default {
  name: 'OpportunityList',
  components: {
    OpportunityFilter,
    VueContext,
    CloneOpportunityModal,
  },
  setup() {
    const { t } = useUtils()
    return { t, permissions }
  },
  data() {
    return {
      items: {},
      tableFilter,
      valuesContext: {},
      valuesModal: {},
      form: {
        sortBy: 'id',
        sortOrder: 'desc',
        size: '10',
        pageNumber: '',
        params: {
          name: '',
          vendor_code: '',
          status: '',
          start_date: '',
          end_date: '',
          period: '',
        },

      },
      cloneModalData: [],
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'name', label: this.$i18n.t('Title'), sortable: true },
        { key: 'service_name', label: this.$i18n.t('Service Name'), sortable: true },
        { key: 'lead_assign_name', label: this.$i18n.t('Lead Assign Name'), sortable: false },
        { key: 'vendor_code', label: this.$i18n.t('Vendor Code'), sortable: true },
        { key: 'vendor_name', label: this.$i18n.t('Vendor Name'), sortable: true },
        { key: 'opporunity_status_name', label: this.$i18n.t('Status'), sortable: true },
        { key: 'vendor_type_name', label: this.$i18n.t('Vendor Type'), sortable: true },
        { key: 'created_by_name', label: this.$i18n.t('Created By'), sortable: true },
      ]
    },
    menuData() {
      return [
        // { id: 1, icon: 'EyeIcon', text: this.$i18n.t('View Details') },
        { id: 2, icon: 'EyeIcon', text: this.$i18n.t('View More Details') },
        // { id: 3, icon: 'SendIcon', text: this.$i18n.t('Send TO EPR') },
      ]
    },
  },
  methods: {
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getOpportunity()
    },
    GetSearchFilter(val) {
      this.form = val
      this.getOpportunity()
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    recieveData(val) {
      this.items = val
    },
    OpenRegenerateOpportunityModal(event) {
      showLoader()
      this.$bvModal.show('CloneOpportunityModal')
      this.cloneModalData = event
      hideLoader()
    },
    async getOpportunity() {
      showLoader()
      await store.dispatch('opportunityStore/getOpportunityList', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.items = response.data.data
          // eslint-disable-next-line eqeqeq
        } else if (response.data.code == constants.ERROR_500) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          document.getElementById('loading-bg').classList.remove('loading-bg')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/lead-generate/lead-opportunity-list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.valuesContext = item
      this.$refs.leadsContextMenu.open(event, item)
    },
    optionClicked(id) {
      if (id === 1) {
        document.getElementById('loading-bg').classList.add('loading-bg')
        store.dispatch('ManageLeads/getLeadById', { lead_generation_id: this.valuesContext.lead_id }).then(response => {
          this.valuesModal = response.data.data
          this.$bvModal.show('ViewLeadModal')
          document.getElementById('loading-bg').classList.remove('loading-bg')
        }).catch(() => {
          document.getElementById('loading-bg').classList.remove('loading-bg')
        })
      } else if (id === 2) {
        this.$router.push(`/opportunity/${this.valuesContext.lead_opportunity_id}`)
      }
    },
    async sendToEPR() {
      showLoader()
      await store.dispatch('opportunityStore/moveToEPR', { opportunity_id: this.valuesContext.lead_opportunity_id }).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          // eslint-disable-next-line eqeqeq
        } else if (response.data.code == constants.ERROR_500) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<template>
  <b-modal
    id="CloneOpportunityModal"
    :title="modalTitle"
    size="lg"
    hide-footer
  >
    <b-card>
      <validation-observer
        ref="FormValidation"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="checkValidation"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="t('Assign Services')"
                label-for="vi-assign-services"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Assign Services"
                  vid="vi-assign-services"
                  rules="required"
                >
                  <v-select
                    v-model="form.assign_services"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :placeholder="t('Select')"
                    :reduce="item => item.id"
                    :options="services"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                :label="t('Finacial Year')"
                label-for="vi-finacial-year"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  class="input-group"
                  name="Finacial Year"
                  vid="vi-finacial-year"
                  rules="required"
                >
                  <v-select
                    v-model="form.finacial_year"
                    class="p-0 border-0 w-100"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :placeholder="t('Select')"
                    :reduce="item => item.id"
                    :options="finacialYear"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
              >
                {{ t('Submit') }}
              </b-button>
              <b-button
                type="button"
                variant="outline-secondary"
                @click="modalCancel"
              >
                {{ t('Cancel') }}
              </b-button>
            </b-col>
          </b-row></b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: ['cloneModalData'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      openModal: false,
      modalTitle: '',
      modalData: [],
      services: [],
      finacialYear: [],
      form: {
        assign_services: null,
        finacial_year: null,
      },
    }
  },
  watch: {
    cloneModalData: {
      handler(val) {
        this.modalTitle = val.name
        this.modalData = val
        this.openModal = true
      },
    },
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', componentId => {
      if (componentId === 'CloneOpportunityModal') {
        this.openModal = false
        this.form = {
          assign_services: null,
          finacial_year: null,
        }
      }
    })
  },
  methods: {
    async onSubmit() {
      showLoader()
      await store.dispatch('opportunityStore/cloneOpportunity', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$bvModal.hide('CloneOpportunityModal')
          // this.$root.$emit('refreshLeadData')
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: '',
            },
          })
          this.$refs.FormValidation.reset()
        } else {
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.msg,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    modalCancel() {
      this.$bvModal.hide('CloneOpportunityModal')
    },
    checkValidation() {
      this.$refs.FormValidation.validate().then(success => {
        if (success) {
          this.onSubmit()
        }
      })
    },
  },
}
</script>
